import React, { useState } from 'react';
import { bool, func, node, number } from 'prop-types';
import classNames from 'classnames';

import css from './PopupOpenerButton.module.css';

const SortByIcon = ({ className }) => {
  const classes = classNames(css.icon, className);
  return (
    <svg className={classes} width="8" height="5" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.764 4.236c.131.13.341.13.472 0l2.666-2.667a.333.333 0 10-.471-.471L4 3.528l-2.43-2.43a.333.333 0 10-.471.471l2.665 2.667z"
        fill="currentColor"
        stroke="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

const PopupOpenerButton = props => {
  const { isSelected, labelMaxWidth, toggleOpen, children, isOpen } = props;

  const iconArrowClassName = classNames(
    css.icon,
    isOpen ? css.iconArrowAnimation : null,
    isSelected ? css.iconSelected : null
  );

  const labelStyles = isSelected ? css.labelSelected : css.label;
  const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
  const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;

  return (
    <button
      className={classNames(labelStyles, labelMaxWidthStyles)}
      style={labelMaxWidthMaybe}
      onClick={() => toggleOpen()}
    >
      {children}
      <SortByIcon className={iconArrowClassName} />
    </button>
  );
};

PopupOpenerButton.defaultProps = {
  isSelected: false,
  labelMaxWidth: null,
  isOpen: false,
};

PopupOpenerButton.propTypes = {
  isSelected: bool,
  toggleOpen: func.isRequired,
  children: node.isRequired,
  labelMaxWidth: number,
  isOpen: bool,
};

export default PopupOpenerButton;
